import React from 'react'
import Hero from './sections/Hero'
import Partners from './sections/Partners'
import Products from './sections/Products'
import Testimonials from './sections/Testimonials'

const HomePage = () => {
    return (
        <>
            <Hero />
            <Products page />
            <Testimonials />
            <Partners />
        </>
    )
}

export default HomePage