import { Container, styled, Typography } from '@mui/material'
import React from 'react'
import { data } from '../../../config/data'
import InfiniteSlider from '../../common/InfiniteSlider'

const StyledTypoGraphy = styled(Typography)(({ theme }) => ({
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '129%',
    background: ' #0099da',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6)
    }
}))

const Partners = () => {
    const { logos } = data
    return (
        <>
            <Container maxWidth='md' disableGutters>
                <StyledTypoGraphy>Our Associated Partners</StyledTypoGraphy>
            </Container>
            <Container maxWidth={false} disableGutters sx={{ py: { xs: 2, md: 6 } }}>
                <InfiniteSlider logos={logos} />
            </Container>
        </>
    )
}

export default Partners