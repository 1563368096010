import React from 'react'

import { styled, Typography } from '@mui/material'

const StyledMenu = styled(Typography)({
  fontFamily: "'Cinzel'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "28px",
  color: "#0A142F"
})

const FooterMenu = ({ children }) => {
  return (
    <StyledMenu>
      {children}
    </StyledMenu>
  )
}

export default FooterMenu