export const data = {
    pages: [
        { title: 'Home', link: '/' },
        { title: 'Products', link: '/products' },
        { title: 'Gallery', link: '/gallery' },
        { title: 'Network', link: '/network' },
        { title: 'Contact Us', link: '/contact' },
    ],
    products: [
        {
            id: 'diamond',
            title: 'Diamond',
            category: 'R. O. Systems',
            price: 14999,
            discount: 10,
            tags: 'R. O. Systems',
            description: 'Perfect choice of RO for your family, Lefesure Aqua\'s Diamond variant ensures maximum purification of water without any loss in its natural taste and qualities. Its RO + UV + UF technique removes microbes from water, flushes out dead pathogens, and provide improved water savings. Its anti-bacterial properties are the best in the industry. The purifier is of 10 litre capacity, and has a very stylish look, which will merge well with the design of your kitchen.',
            image: '/images/products/diamond.jpeg'
        },
        {
            id: 'crown',
            title: 'Crown',
            category: 'R.O. System',
            price: 14999,
            discount: 10,
            tags: 'R.O. System',
            description: 'The Crown purifier from Jet Aqua RO range of water filters provide 5-stage purification for your drinking water without using any electricity. This table-top water purifier gives you clean and pure water anytime anywhere. This affordable water filter is perfect for using in homes and commercial spaces. The sleek look go prefectly with the interiors of your kitchen.',
            image: '/images/products/crown.jpeg'
        },
        {
            id: 'platinum',
            title: 'Platinum',
            category: 'R.O. System',
            price: 14999,
            discount: 10,
            tags: 'R.O. System',
            description: 'The Platinum purifier from Jet Aqua RO range of water filters provide 5-stage purification for your drinking water without using any electricity. This table-top water purifier gives you clean and pure water anytime anywhere. This affordable water filter is perfect for using in homes and commercial spaces. The sleek look go prefectly with the interiors of your kitchen.',
            image: '/images/products/platinum.jpeg'
        },
        {
            id: 'gold',
            title: 'Gold',
            category: 'R.O. System',
            price: 14999,
            discount: 10,
            tags: 'R.O. System',
            description: 'The Gold purifier from Jet Aqua RO range of water filters provide 5-stage purification for your drinking water without using any electricity. This table-top water purifier gives you clean and pure water anytime anywhere. This affordable water filter is perfect for using in homes and commercial spaces. The sleek look go prefectly with the interiors of your kitchen.',
            image: '/images/products/gold.jpeg'
        },
    ],
    footerLinks: [
        {
            header: 'Sitemap',
            menus: [
                { title: 'About Us', link: '/about_us' },
                { title: 'Contact Us', link: '/contact' },
                { title: 'Fedback', link: '/feedback' },
                { title: 'Become a partner', link: '/parnership' },
            ]
        },
        {
            header: 'Information',
            menus: [
                { title: 'Water Filter And RO', link: '' },
                { title: 'RO Water Purifiers', link: '' },
                { title: 'Water Purifiers For Home', link: '' },
            ]
        }
    ],
    gallery: [
        { src: '/images/gallery/a88e9289-f120-4ff8-8e47-54d8cdb1d7e8.jpeg' },
        { src: '/images/gallery/e9c8d8f7-5d2b-4340-98a4-d4a0060551a8.jpeg' },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733879/lifeaqua-sure/gallery/gallery_p13_n4aqic.png", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733879/lifeaqua-sure/gallery/gallery_p12_vtqsjd.png", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733879/lifeaqua-sure/gallery/gallery_p14_orc5l5.png", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733879/lifeaqua-sure/gallery/gallery_g4_bstz1u.png", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733878/lifeaqua-sure/gallery/gallery_g2_x9vyer.jpg", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733878/lifeaqua-sure/gallery/gallery_g3_q9ermw.png", },
        { src: "https://res.cloudinary.com/dhergobtc/image/upload/v1671733878/lifeaqua-sure/gallery/gallery_g1_cgwse6.jpg", },
    ],
    logos: [
        "/images/Partners/ALGO.png",
        "/images/Partners/BNSQ.png",
        "/images/Partners/BODEER.png",
        "/images/Partners/CIL.png",
        "/images/Partners/CRI.png",
        "/images/Partners/CSM.png",
        "/images/Partners/Hero.png",
        "/images/Partners/Hydromatic.png",
        "/images/Partners/KEMFLOW.png",
        "/images/Partners/KFLOW.png",
        "/images/Partners/Sanlixin.png",
        "/images/Partners/ST.png",
    ],
    reviews: [
        {
            avatar: '',
            header: 'Ashad S',
            star: 4.3,
            description: 'Working with life sure aqua and the team has been a hassle-free experience. They are always on their toes to meet our requirements and are quick to work on the feedback. '
        },
        {
            header: 'Aniket T',
            star: 5,
            description: 'I just wanted to send you a quick note to tell you how happy we have been with the excellent customer service we have received from you. Thank you. Great work '
        },
        {
            header: 'Sagar Naik',
            star: 4,
            description: '"Very satisfied with the Aqua guard product. Thanks for your quick service.”'
        },
        {
            header: 'Vishal Patil',
            star: 5,
            description: 'Very satisfied with your service sir. our water plant is working very smoothly and the water is just good  healthy and tasty'
        },
    ],
    address: {
        building: 'New Timeber market Urali - Devachi, ',
        street: 'Hadapsar,',
        locality: 'Saswad Road',
        tal: 'Pune',
        dist: 'Pune',
        state: 'Maharashtra',
        pin: '412308',
    },
    coord: { lat: 18.489795392592836, lng: 73.93251740851794 }

}