import { Box, Container, styled } from '@mui/material'
import React from 'react'

const PREFIX = 'InfiniteSlider'

const classes = {
    sliderContainer: `${PREFIX}-sliderContainer`,
    sliderWrapper: `${PREFIX}-sliderWrapper`,
    slidesList: `${PREFIX}-slidesList`,
    slideItem: `${PREFIX}-slideItem`
}

const StyledInfiniteSlider = styled(Container)(({ theme }) => ({
    '@keyframes scroll': {
        'from': { transform: "translateX(0%)" },
        'to': { transform: "translateX(-100%)" }
    },
    [`& .${classes.sliderContainer}`]: { position: 'relative', width: '100%', height: '100px', overflow: 'hidden' },
    [`& .${classes.sliderWrapper}`]: {
        position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, height: '100%',
        '&::after': {
            background: 'linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
            content: "''",
            height: '100px',
            position: 'absolute',
            width: '200px',
            zIndex: 2,
            right: 0,
            top: 0,
            transform: 'rotateZ(180deg)',
        },
        '&::before': {
            background: 'linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
            content: "''",
            height: '100px',
            position: 'absolute',
            width: '200px',
            zIndex: 2,
            left: 0,
            top: 0,
        }
    },
    [`& .${classes.slidesList}`]: {
        marginLeft: '0%', width: '3696px', margin: '2px', height: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', animation: 'scroll linear 30s infinite',
    },
    [`& .${classes.slideItem}`]: { height: '100%', width: 'auto' },
}))

const InfiniteSlider = ({ logos }) => {
    return (
        <StyledInfiniteSlider maxWidth={false} disableGutters>
            <Box className={classes.sliderContainer}>
                <Box className={classes.sliderWrapper}>
                    <Box className={classes.slidesList}>
                        {
                            [...logos, ...logos, ...logos].map(el => (
                                <img alt='Lifesure Aqua' src={el} className={classes.slideItem} />
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </StyledInfiniteSlider>
    )
}

export default InfiniteSlider