import { Box, Card, CardActions, CardContent, CardHeader, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import offerPrice from '../../../../utils/offerPrice';
import StyledButton from '../../../common/Button';
const PREFIX = 'Product';

const classes = {
    card: `${PREFIX}-card`,
    container: `${PREFIX}-container`,
    contentContainer: `${PREFIX}-contentContainer`,
    mediaContainer: `${PREFIX}-mediaContainer`,
    rowHeader: `${PREFIX}-rowHeader`,
    rowData: `${PREFIX}-rowData`,
    description: `${PREFIX}-description`,
}

const PStyled = styled(Card)(({ theme, id }) => ({
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        height: 'auto',
        display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [`& .${classes.container}`]: {
        [theme.breakpoints.down('md')]: {
            minHeight: '200px',
            minWidth: '100%',
        }
    },
    [`& .${classes.contentContainer}`]: {
        [theme.breakpoints.down('md')]: {
            order: 2
        },
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            position: 'relative',
            overflowX: 'hidden',
            overflowY: 'hidden',
            // order: id % 2 === 0 ? 2 : 1
        }
    },
    [`& .${classes.mediaContainer}`]: {
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
        backgroundRepeatX: 'no-repeat',
        minHeight: '220px',
        [theme.breakpoints.down('md')]: {
            order: 1,
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            // order: id % 2 === 0 ? 1 : 2
        }
    },
    [`& .${classes.rowHeader}`]: {
    },
    [`& .${classes.rowData}`]: {
    },
    [`& .${classes.description}`]: {
        fontWeight: 600,
        fontSize: '12px',
        // color: '#1B18F9',
        zIndex: 9999,
        [theme.breakpoints.up('sm')]: {
            paddingTop: '50px'
        }
    }
}))

const Product = ({ product, id }) => {
    return (
        <PStyled className={classes.card} >
            <Grid container className={classes.container} spacing={0}>
                <Grid
                    item
                    sm={6}
                    className={classes.mediaContainer}
                    style={{
                        backgroundImage: `url(${product.image})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </Grid>
                <Grid item sm={6} p={2} className={classes.contentContainer}>
                    <Box sx={{ zIndex: 1, position: 'relative' }}>
                        <CardHeader title={product.title} subheader={[product.category, product.tags].join(' - ')} />
                        <CardContent>
                            <Box sx={{ width: '100%' }}>
                                Price:
                                <Typography component='span' sx={{ textDecoration: "line-through" }}>{' '}{product.price}{'₹ '}</Typography>
                                <Typography component='span' sx={{ fontSize: '20px', fontWeight: 700 }}>{offerPrice(product.price, product.discount)}₹</Typography>
                            </Box>
                            <Typography className={classes.description}>
                                {product.description.replace(/^(.{220}[^\s]*).*/, "$1")}...
                            </Typography>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '20PX' }}>
                            <StyledButton variant='outlined' size='small' LinkComponent={Link} to={`/products/${product.id}`}>
                                Product Details
                            </StyledButton>
                        </CardActions>
                    </Box>
                </Grid>
            </Grid>
        </PStyled>
    )
}

export default Product