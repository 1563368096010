import { Box, styled } from '@mui/material';
import React from 'react';
import './../../assets/css/background.css';

const WaveBox = styled(Box)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 'calc(100% + 320px)',
    height: 'calc(60% + 32px)',
    background: 'url(./images/wave2.svg)',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'x-repeat',
})
const BackGround2 = ({ children, style = {} }) => {
    return (
        <Box sx={{ width: '100%', height: '100%', display: { sx: 'none', md: 'block' }, position: 'relative' }}>
            <Box className='parallax' sx={{ width: 'calc(100% + 150px)', position: 'absolute', height: '100%', bottom: '-16px', marginLeft: '-200px', ...style }} >
                <WaveBox className='wave1' />
                <WaveBox className='wave2' />
                <WaveBox className='wave3' />
            </Box>
            {children}
        </Box>
    )
}

export default BackGround2