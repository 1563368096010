import { Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as Illustration } from "../../../assets/Illustration.svg";

const PREFIX = "HERO";

const classes = {
    header: `${PREFIX}-header`,
    subheader: `${PREFIX}-subheader`,
    illustration: `${PREFIX}-illustration`,
}

const StyledHero = styled(Container)(({ theme }) => ({
    [`& .${classes.header}`]: {
        [theme.breakpoints.up('md')]: {
            width: '420px'
        },
        fontFamily: 'Spartan',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '129%',
        background: '#0099da',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    [`& .${classes.subheader}`]: {
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(4)} 0`
        },
        [theme.breakpoints.up('md')]: {
            width: '500px',
            padding: '20px 0'
        },
        fontFamily: 'Spartan',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '171.5%',
    },
    [`& .${classes.illustration}`]: {
        maxHeight: '600px',
        ['svg']: {
            [theme.breakpoints.down('md')]: {
                height: '50vh'
            }
        }
    }
}))

const Hero = () => {
    return (
        <StyledHero maxWidth='lg' disableGutters>
            <Grid container alignItems='center' justifyContent='center'>
                <Grid item md={7} p={2} order={{ xs: 2, md: 1 }}>
                    <Typography className={classes.header}>Better Quality and superior performance</Typography>
                    <Typography className={classes.subheader}>
                        We at Lifesure Aqua  are working tirelessly with the aim to provide clean drinking water to each and every person in the community.</Typography>
                </Grid>
                <Grid classes={{ root: classes.illustration }} item md={5} order={{ xs: 1, md: 2 }} >
                    <Illustration style={{ maxWidth: '100%' }} />
                </Grid>
            </Grid >
        </StyledHero >
    )
}

export default Hero