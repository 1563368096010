import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material';
import { data } from '../config/data';
import { Link } from 'react-router-dom';

const PREFIX = 'NAVBAR';

const classes = {
    mobileLogo: `${PREFIX}-mobileLogo`,
    desktopLogo: `${PREFIX}-desktopLogo`,
}

const StyledNavbar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.05) 0px 0px 8px',
    background: 'white',
    [`& .${classes.desktopLogo}`]: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    [`& .${classes.mobileLogo}`]: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        },
        display: 'none',
    }
}))
const { pages } = data
function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <StyledNavbar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <img alt='Lifesure Aqua' src='/images/logo.png' className={classes.desktopLogo} style={{ height: '60px', width: 'auto' }} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.link} onClick={handleCloseNavMenu}>
                                    <Button LinkComponent={Link} to={page.link} textAlign="center">{page.title}</Button>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <img alt='Lifesure Aqua' src='/images/logo.png' className={classes.mobileLogo} style={{ height: '60px', width: 'auto' }} />
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        {pages.map((page) => (
                            <Button
                                LinkComponent={Link}
                                key={page.link}
                                to={page.link}
                                sx={{ my: 2, color: 'grey', display: 'block' }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </StyledNavbar>
    );
}
export default Navbar;
