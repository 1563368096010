import { Button as MuiButton, styled } from '@mui/material'
const generateGradient = (palette) => {
    return `linear-gradient(180deg, ${palette.primary.main} 35%, ${palette.secondary.main} 95%)`
}
const StyledButton = styled(MuiButton)(({ theme, variant }) => {
    return ({
        ...variant === 'contained' ? ({ color: 'white', background: generateGradient(theme.palette) }) : {},

    })
})

export default StyledButton