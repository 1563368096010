import React from 'react'
import BookDemo from '../components/common/Widgets/BookDemo'
import Navbar from '../components/Navbar'
import Routes from '../config/routes'

export default function Index() {
    return (
        <>
            <Routes>
                <BookDemo />
                <Navbar />
            </Routes>
        </>
    )
}