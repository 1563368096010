import { Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { data } from '../../../../config/data'
import Product from './Product'

const StyledTypoGraphy = styled(Typography)(({ theme }) => ({
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '129%',
    background: ' #0099da',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}))

const StyledProduct = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}))

const Products = ({ page }) => {
    const products = data.products
    console.log(page);
    return (
        <>
            {!page ?
                (<Container style={{
                    backgroundImage: 'url("/images/91692.jpg")',
                    backgroundSize: 'cover',
                    marginBottom: '20px'
                }} maxWidth={false} disableGutters>
                    <Typography
                        textAlign='center'
                        color='white'
                        variant='h5'
                        pt={5}
                        pb={5}
                    >
                        Products
                    </Typography>
                </Container>) :
                (<Container maxWidth='md' disableGutters>
                    <StyledTypoGraphy>Our Products</StyledTypoGraphy>
                </Container>)}
            <Container maxWidth={false}>
                {
                    page ?
                        products.slice(0, 2).map((product, id) =>
                            <StyledProduct maxWidth='md' disableGutters sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
                                <Product product={product} key={id} id={id} />
                            </StyledProduct>
                        ) :
                        (
                            <StyledProduct maxWidth='lg' disableGutters>
                                <Grid container spacing={5}>
                                    {products.map((product, id) =>
                                        <Grid item md={6}>
                                            <Product product={product} key={id} id={id} />
                                        </Grid>
                                    )}
                                </Grid>
                            </StyledProduct>
                        )
                }
            </Container>
        </>
    )
}

export default Products