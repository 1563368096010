import { Star, StarBorder, StarHalf } from '@mui/icons-material'
import React from 'react'

const Rating = ({ rate }) => {
    const full = Math.floor(rate)
    const data = [...Array(5).keys()].map((_, id) => id + 1 < rate ? 1 : (id === full ? 0.5 : 0))
    return (
        <>
            {
                data.map(el => (el === 1 && <Star />) || (el === 0.5 && <StarHalf />) || (el === 0 && <StarBorder />))
            }
        </>
    )
}

export default Rating