import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import Testimonial from './Testimonial'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { data } from '../../../../config/data';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const PREFIX = 'Testimonial';

const classes = {
    arrow: `${PREFIX}-arrow`,
    arrowContainer: `${PREFIX}-arrowContainer`,
    sweapable: `${PREFIX}-sweapable`,
}

const TStyled1 = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        margin: `${theme.spacing(12)} 0`,
    },
    [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(8)} 0`,
    },
    [`& .${classes.arrow}`]: {
        height: theme.spacing(8),
        width: theme.spacing(8),
        color: '#0099da'
    },
    [`& .${classes.arrowContainer}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    [`& .${classes.sweapable}`]: {
        background: '#c6f5ff',
        ['& > div > div > div']: {
            overflow: 'hidden',
        }
    }

}))

const StyledTypoGraphy = styled(Typography)(({ theme }) => ({
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '129%',
    background: '#0099da',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6)
    }
}))

const { reviews } = data
const Testimonials = () => {
    const [index, setIndex] = React.useState(0);
    const handlePrev = () => {
        setIndex(index - 1)
    };
    const handleNext = () => {
        setIndex(index + 1)
    };
    const handleChangeIndex = index => {
        setIndex(index)
    };
    return (
        <>
            <Container maxWidth='md' disableGutters>
                <StyledTypoGraphy>What Our Customer Says</StyledTypoGraphy>
            </Container>

            <TStyled1 maxWidth={false}>
                <Grid container>
                    <Grid item md={2.5} className={classes.arrowContainer}>
                        <ArrowCircleLeftOutlined className={classes.arrow} onClick={handlePrev} />
                    </Grid>
                    <Grid item md={7} sx={{ overflow: 'hidden', borderRadius: '44px', border: '1px solid #0099da', boxShadow: '1px 3px 10px 2px #0099da', }}>
                        <Box sx={{ position: 'relative' }}>
                            <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex} className={classes.sweapable}>
                                {reviews.map(data => <Testimonial data={data} />)}
                            </AutoPlaySwipeableViews>
                        </Box>
                    </Grid>
                    <Grid item md={2.5} className={classes.arrowContainer}>
                        <ArrowCircleRightOutlined className={classes.arrow} onClick={handleNext} />
                    </Grid>
                </Grid>
            </TStyled1>
        </>
    )
}

export default Testimonials