import { BookOnlineRounded, DownloadForOfflineRounded, HandshakeRounded } from '@mui/icons-material'
import { Box, IconButton, styled, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const PREFIX = 'BookDemo'

const classes = {
    iconContainer: `${PREFIX}-iconContainer`,
    textBook: `${PREFIX}-textBook`,
    iconBook: `${PREFIX}-iconBook`,
    textPartner: `${PREFIX}-textPartner`,
    iconPartner: `${PREFIX}-iconPartner`,
    textDownload: `${PREFIX}-textDownload`,
    iconDownload: `${PREFIX}-iconDownload`,
}

const StyledBookDemo = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 10,
    left: 10,
    zIndex: 999,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [`& .${classes.iconContainer}`]: {
        display: 'flex',
        alignItems: 'center'
    },
    [`& .${classes.iconBook}`]: {
        color: green[700],
        [theme.breakpoints.down('md')]: {
            width: '60px',
            height: '60px'
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px'
        }
    },
    [`& .${classes.textBook}`]: {
        width: 0,
        height: 'inherit',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'blue',
    },
    [`& :hover + .${classes.textBook}`]: {
        width: '150px',
        transition: 'width 2s'
    },

    [`& .${classes.iconPartner}`]: {
        color: green[700],
        [theme.breakpoints.down('md')]: {
            width: '60px',
            height: '60px'
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px'
        }
    },
    [`& .${classes.textPartner}`]: {
        width: 0,
        height: 'inherit',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'blue',
    },
    [`& :hover + .${classes.textPartner}`]: {
        width: '150px',
        transition: 'width 2s'
    },

    [`& .${classes.iconDownload}`]: {
        color: green[700],
        [theme.breakpoints.down('md')]: {
            width: '60px',
            height: '60px'
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px'
        }
    },
    [`& .${classes.textDownload}`]: {
        width: 0,
        height: 'inherit',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'blue',
    },
    [`& :hover + .${classes.textDownload}`]: {
        width: '150px',
        transition: 'width 2s'
    },
}))

const BookDemo = () => {
    const [mouse, setMouse] = useState();
    return (
        <StyledBookDemo onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
            <Box
                className={classes.iconContainer}
                component='span'
                style={{ display: mouse ? 'flex' : 'none' }}
            >
                <IconButton size='large'
                    LinkComponent={'a'}
                    target='_blank'
                    rel="noopener noreferer"
                    href='/docs/61 Warranty card lifesure aqua.pdf'
                >
                    <DownloadForOfflineRounded className={classes.iconDownload} />
                </IconButton>
                <Typography className={classes.textDownload}> Download Broucher</Typography>
            </Box>
            <Box className={classes.iconContainer} component='span' style={{ display: mouse ? 'flex' : 'none' }}>
                <IconButton size='large'>
                    <HandshakeRounded className={classes.iconPartner} />
                </IconButton>
                <Typography className={classes.textPartner}>Become a Partner</Typography>
            </Box>
            <Box className={classes.iconContainer} component='span'>
                <IconButton size='large' LinkComponent={Link} to='/contact'>
                    <BookOnlineRounded className={classes.iconBook} />
                </IconButton>
                <Typography className={classes.textBook}>Book Demo</Typography>
            </Box>
        </StyledBookDemo>
    )
}

export default BookDemo