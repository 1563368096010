import { Box, Grid, styled, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { data } from '../config/data'
import BackGround2 from './common/Background2'
import FooterMenu from './common/Footer/Menu'
import FooterSubMenu from './common/Footer/SubMenu'
import LocationOn from '@mui/icons-material/LocationOn';
import LocalPhone from '@mui/icons-material/LocalPhone';
import Email from '@mui/icons-material/Email';

const PREFIX = 'Footer';

const classes = {
    footer: `${PREFIX}-footer`,
    companyProfile: `${PREFIX}-companyProfile`,
    company: `${PREFIX}-company`,
    links: `${PREFIX}-links`,
}

const StyledFooter = styled(Container)(({ theme }) => ({
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5)
    },
    [`& .${classes.footer}`]: {
        height: '200px',
    },
    [`& .${classes.companyProfile}`]: {
        marginTop: '20px',
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        textTransform: 'capitalize',
        textDecoration: 'none',
        color: '#0A142F99',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [`&:hover`]: {
            textDecoration: 'underline',
        }
    },
    [`& .${classes.company}`]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'fllex-start',
            textAlign: 'left'
        },
    },
    [`& .${classes.links}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
        width: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3)
        }
    }
}))

const Footer = () => {
    return (
        <StyledFooter maxWidth={false}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img alt='Lifesure Aqua' src='/images/logo.png' style={{ width: 'auto', height: '60px' }} />
                    </Grid>
                    <Grid item md={3} className={classes.links}>
                        {data.footerLinks.map(flink => (
                            <Box>
                                <FooterMenu>{flink.header}</FooterMenu>
                                {flink.menus.map(menu => (
                                    <FooterSubMenu to={menu.link}>
                                        {menu.title}
                                    </FooterSubMenu>
                                ))}
                            </Box>
                        ))}
                    </Grid>
                    <Grid item md={5}>
                        <Box className={classes.company}>
                            <Typography
                                component='a'
                                target='_blank'
                                className={classes.companyProfile}
                                href={`https://maps.google.com/?q=Om Sai Trading, ${data.address.building}, ${data.address.street}, ${data.address.locality}, ${data.address.tal}, ${data.address.dist}, ${data.address.state}, ${data.address.pin}`}>
                                <LocationOn />
                                <p>
                                    <strong>OM SAI TRADING</strong>
                                    <Box sx={{ width: '100%', padding: '5px' }} />
                                    {data.address.building} <br />
                                    {data.address.street}  <br />
                                    {data.address.locality}<br />
                                    Tal.:{data.address.tal}, Dist.:{data.address.dist}, <br />
                                    {data.address.state}, {data.address.pin}
                                </p>
                            </Typography>
                            <Box style={{ flex: { xs: 1 }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <Typography component='a' href='tel:+919822259068' className={classes.companyProfile}>
                                    <LocalPhone />+91 98222 59068
                                </Typography>
                                <Typography component='a' href='tel:+918208696390' className={classes.companyProfile}>
                                    <LocalPhone />+91 82086 96390
                                </Typography>
                                <Typography component='a' href='mailto:sales@lifesureaqua.in' className={classes.companyProfile}>
                                    <Email />lifesureaqua@gmail.com
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box position='relative' sx={{ height: '100px', width: '100%' }}>
                <Box sx={{ position: 'absolute', bottom: 0, height: '100%', width: '100%' }}>
                    <BackGround2>
                        <Typography sx={{ position: 'absolute', bottom: 5, right: 0, color: 'white' }}>All Rights Reserved, 2023</Typography>
                    </BackGround2>
                </Box>
            </Box>
        </StyledFooter>
    )
}

export default Footer