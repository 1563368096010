import { RoomRounded } from '@mui/icons-material';
import React from 'react';

const Marker = (props) => {
    const { color, name } = props;
    return (
        <RoomRounded style={{ color, cursor: 'pointer', fontSize: '30px' }} titleAccess={name} />
    );
};

export default Marker;