import { Box, Button, Card, CardContent, CardMedia, Container, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import getProduct from '../../utils/getProduct'

const PREFIX = 'ProductDetails';

const classes = {
    header: `${PREFIX}-header`
}

const StyledPDetails = styled(Container)({
    [`& .${classes.header}`]: {
        backgroundImage: 'url("/images/91692.jpg")',
        backgroundSize: 'cover'
    }
})

const ProductDetails = () => {

    let { productId } = useParams()
    let data = getProduct(productId)
    return (
        <StyledPDetails maxWidth={false} disableGutters>
            <Container className={classes.header} maxWidth={false} disableGutters>
                <Typography
                    textAlign='center'
                    color='white'
                    variant='h5'
                    pt={5}
                    pb={5}
                >
                    {data.title}
                </Typography>
            </Container>
            <Container maxwidth='lg'>
                <Grid item xs={12} pt={5}>
                    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                        <Box sx={{ flex: 1 }}>
                            <CardMedia
                                component="img"
                                sx={{ flex: 1 }}
                                image={data.image}
                                alt="Live from space album cover"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5" pb={3}>
                                    {data.title}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    <b>Category: </b>{data.category}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div" pb={5}>
                                    <b>Tags: </b>{data.tags}
                                </Typography>
                                <Button variant='outlined'>Get Quote</Button>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
            </Container>
            <Container maxwidth='lg'>
                <Grid item xs={12} pt={5}>
                    <Card sx={{ padding: '20px' }}>
                        {data.description}
                    </Card>
                </Grid>
            </Container>
        </StyledPDetails>
    )
}

export default ProductDetails 