import { Container, styled, Typography } from '@mui/material';
import { Gallery } from 'react-grid-gallery'
import React, { useState } from 'react'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { data } from '../../config/data'


const StyledGallery = styled(Container)(({ theme }) => ({
    ['& .ReactGridGallery']: {
        ['& div:nth-of-type(1)']: {
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center'
            }
        }
    }
}))

const images = data.gallery
const GalleryPage = () => {
    const [index, setIndex] = useState(-1);
    const [isOpen, setIsOpen] = useState();
    // let currentImage = images[index];
    const handleClick = (index, item) => {
        setIndex(index)
        setIsOpen(true)
    };
    return (
        <>
            <Container style={{
                backgroundImage: 'url("/images/91692.jpg")',
                backgroundSize: 'cover',
                marginBottom: '20px'
            }} maxWidth={false} disableGutters>
                <Typography
                    textAlign='center'
                    color='white'
                    variant='h5'
                    pt={5}
                    pb={5}
                >
                    Gallery
                </Typography>
            </Container>
            <StyledGallery maxWidth='lg'>
                <Gallery
                    images={data.gallery}
                    onClick={handleClick}
                    enableImageSelection={false}

                />
                {isOpen && (
                    <Lightbox
                        mainSrc={images[index]?.src || ''}
                        nextSrc={images[(index + 1) % images.length]}
                        prevSrc={images[(index + images.length - 1) % images.length]}
                        onCloseRequest={() => {
                            setIsOpen(false)
                            setIndex(-1)
                        }}
                        onMovePrevRequest={() =>
                            setIndex((index + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setIndex((index + 1) % images.length)
                        }
                    />
                )}
            </StyledGallery>
        </>
    )
}

export default GalleryPage