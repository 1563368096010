
const { createTheme } = require("@mui/material");

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1B18F9',
            light: '#1B18F9',
        },
        secondary: {
            main: '#D143B3',
        },
        error: {
            main: '#fb1300',
        },
        warning: {
            main: '#ffb900',
        },
    },
});