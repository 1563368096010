import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { Box } from '@mui/material';


const SimpleMap = ({ coord, address }) => {
    const getMapOptions = (maps) => {
        return {
            disableDefaultUI: true,
            mapTypeControl: true,
            streetViewControl: true,
            styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
        };
    };
    const center = coord;
    const zoom = 17;

    return (
        <Box style={{ height: '60vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBzWsiXI8GCpkQQd5KE_UEImH5AgrqkJUw' }}
                defaultCenter={center}
                defaultZoom={zoom}
                options={getMapOptions}
            >
                <Marker
                    {...{ coord }}
                    color='red'
                    name={address || ''}
                    text="My Marker"
                />
            </GoogleMapReact>
        </Box>
    );
}

export default SimpleMap;