import { ThemeProvider } from '@mui/material';
import './App.css';
import { theme } from './config/theme';
import Pages from './pages';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Pages />
    </ThemeProvider>
  );
}

export default App;
