import React from 'react'
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from "react-router-dom";
import BookDemo from '../components/common/Widgets/BookDemo';
import Contact from '../components/ContactPage';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ProductDetails from '../components/ProductDetailsPage';
import Gallery from '../pages/Gallery';
import Home from '../pages/Home'
import ProductPage from '../pages/Products';

const NavabrWrapper = () => {
    return (
        <>
            <BookDemo />
            <Navbar />
            <Outlet />
        </>)
}
const router = createBrowserRouter([
    {
        path: "/",
        element: <NavabrWrapper />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: '/gallery',
                element: <Gallery />,
            },
            {
                path: '/products/:productId',
                element: <ProductDetails />
            },
            {
                path: '/products',
                element: <ProductPage />,
            },
            {
                path: "/contact",
                element: <Contact />,
            },
            {
                path: "*",
                element: <Home />,
            },
        ]
    }
]);
const Routes = ({ children }) => {
    return (
        <>
            <RouterProvider router={router} />
            <Footer />
        </>
    )
}

export default Routes