import { Box, Button, Card, CardContent, CardHeader, Container, Grid, styled, TextField } from '@mui/material'
import React from 'react'
import SimpleMap from '../common/Map/SimpleMap'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { data } from '../../config/data';
import { Email, Phone } from '@mui/icons-material';
import emailjs from '@emailjs/browser';


const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    name: yup
        .string('Enter your name')
        .min(3, 'Name should be of minimum 3 characters length')
        .required('Name is required'),
    phone: yup
        .string('Enter your phone no')
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone is not valid.')
        .required('Phone is required'),
});
let { coord } = data

const PREFIX = 'Contact';

const classes = {
    form: `${PREFIX}-form`,
    formFooter: `${PREFIX}-formFooter`,
    contact: `${PREFIX}-contact`,
}

const StyledContact = styled(Container)((theme) => ({
    [`& .${classes.form}`]: {
        height: '100%',
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    [`& .${classes.formFooter}`]: {
        flex: 1.5,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    [`& .${classes.contact}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    }
}))

const Contact = () => {
    const formik = useFormik({
        initialValues: {
            // email: 'john.doe@example.com',
            // name: 'John Doe',
            // phone: '99999999999',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values)
            emailjs
                .send('default_service', 'template_lyvntzn', values, {
                    publicKey: 'jtlBBK_fe1s_19xJ_',
                })
                .then(
                    () => {
                        alert('SUCCESS!');
                    },
                    (error) => {
                        alert(JSON.stringify(error));
                    },
                );
        },
    });
    return (
        <StyledContact maxWidth='lg' sx={{ padding: 5 }}>
            <Card>
                <CardContent>
                    <Grid container spacing={6}>
                        <Grid item md={6}>
                            <form className={classes.form} onSubmit={formik.handleSubmit}>
                                <CardHeader title='Get In Touch' subheader='Thank you for your interest in contacting us. Please fill out the form below and we will get back to you as soon as possible.' sx={{ flex: 2 }} />
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="name"
                                    name="name"
                                    label="Full Name"
                                    sx={{ flex: 1 }}
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="email"
                                    name="email"
                                    label="Email"
                                    sx={{ flex: 1 }}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="phone"
                                    name="phone"
                                    label="Phone No."
                                    sx={{ flex: 1 }}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ flex: 0.5 }}>
                                    Send
                                </Button>
                                <Box className={classes.formFooter}>
                                    <Box className={classes.contact}>
                                        <Phone />
                                        <Box component='span'>+91 98222 59068</Box>
                                    </Box>
                                    <Box className={classes.contact}>
                                        <Email />
                                        <Box component='span'>lifesureaqua@gmail.com</Box>
                                    </Box>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item md={6} sx={{ maxWidth: '100%', width: '599px' }}>
                            <SimpleMap coord={coord} name='Om Sai Trading' />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </StyledContact>
    )
}

export default Contact