import { Avatar, Box, Card, CardContent, CardMedia, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import Rating from './Rating';

const PREFIX = 'Testimonial';

const classes = {
    avatar: `${PREFIX}-avatar`,
    header: `${PREFIX}-header`,
    star: `${PREFIX}-star`,
    description: `${PREFIX}-description`,
    cardContent: `${PREFIX}-cardContent`,
    right: `${PREFIX}-right`,
}

const TStyled = styled(Card)(({ theme }) => ({
    zIndex: 2,
    display: 'flex',
    background: 'transparent',
    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5),
        flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
        height: '470px',
    },
    [`& .${classes.avatar}`]: {
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(25),
            height: theme.spacing(25),
        },
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(15),
            height: theme.spacing(15),
        },
        [`& img`]: {
            position: 'relative',
        }
    },
    [`& .${classes.header}`]: {
        fontFamily: 'Spartan',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '36px',
        lineHeight: '129%',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    [`& .${PREFIX}-description`]: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '110%',
        textAlign: 'center',
        color: '#1B18F9',
        maxWidth: '500px',
    },
    [`& .${PREFIX}-cardContent`]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-around'
    },
    [`& .${PREFIX}-right`]: {
        height: '100%',
        flex: 1
    }
}))

const Testimonial = ({ data }) => {
    return (
        <TStyled elevation={0}>
            <Grid container sx={{ height: '100%', zIndex: 998, flex: 1 }}>
                <Grid item md={5} style={{ height: '100%', width: '100%' }}>
                    <CardMedia style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Avatar alt="Remy Sharp" src={data.avatar || 'https://cdn-icons-png.flaticon.com/512/260/260507.png'} className={classes.avatar} />
                    </CardMedia>
                </Grid>
                <Grid item md={7} className={classes.right}>
                    <CardContent className={classes.cardContent}>
                        <Box>
                            <Typography className={classes.header}>{data.header}</Typography>
                            <Box style={{ margin: '27px 0', display: 'flex', width: '231px', justifyContent: 'space-between', color: '#0099da', }}>
                                <Rating rate={data.star} />
                            </Box>
                        </Box>
                        <Box zIndex={9999}>
                            <Typography className={classes.description}>
                                <Box component='span' style={{ fontWeight: 700, fontSize: '85px', color: '#1B18F9' }}>&#8216;</Box>
                                {data.description}
                            </Typography>
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </TStyled>
    )
}

export default Testimonial