import React from 'react'
import { Link as ReactLink } from 'wouter'
import { Box, Link as MuiLink, styled } from '@mui/material'

const StyledSubMenu = styled(Box)({
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0A142F",
    opacity: "0.75"
})

const FooterSubMenu = ({ children, to }) => {
    return (
        <StyledSubMenu>
            <MuiLink component={ReactLink} to={to} underline='hover' >
                {children}
            </MuiLink>
        </StyledSubMenu>
    )
}

export default FooterSubMenu